import React, { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { embedDashboard, EmbeddedDashboard, EmbedDashboardParams } from '@superset-ui/embedded-sdk'
import { getApi } from '../OrganizationSignUp/frontend-api'
import firebase, { asFirebase } from '../../utils/firebase'

function fetchGuestTokenFromBackend(dashboardId: string) {
    return async () => {
        const api = await getApi(asFirebase(firebase))
        const serverResp = await api['fetch-reporting-guest-token'].$post({
            json: { dashboardId }
        })

        const response = await serverResp.json()
        if ('jwt' in response) {
            return response.jwt
        } else {
            throw new Error(response.error)
        }
    }
}

async function fetchConfig() {
    const api = await getApi(asFirebase(firebase))
    const resp = await api['fetch-reporting-config'].$get()
    const config = await resp.json()

    if ('error' in config) throw new Error(config.error)
    return config
}

// Superset Domain is fetched from the server, but can be
// explicitly overridden here.
type SupersetDashboardProps = Partial<EmbedDashboardParams> & {
    dashboardId: string
    supersetDomain?: string
}

function styleMountpoint(div: HTMLDivElement) {
    div.style.width = '100%'
    div.style.height = '100%'
    div.style.border = '0'
}

function styleDashboardIframe(mountpoint: HTMLDivElement) {
    const iframe = mountpoint.querySelector('iframe')
    if (iframe) {
        iframe.style.width = '100%'
        iframe.style.height = '100%'
        iframe.style.border = '0'
    } else {
        console.warn('Could not find iframe of the embedded dashboard')
    }
}

const defaultFiltersConfig = {
    expanded: false
}

export function SupersetDashboard(props: SupersetDashboardProps) {
    const mountpointRef = useRef<HTMLDivElement | null>(null)
    let myDashboard: EmbeddedDashboard | null = null

    useEffect(() => {
        async function mountDashboard() {
            if (!mountpointRef.current) {
                mountpointRef.current = document.createElement('div')
                styleMountpoint(mountpointRef.current)
            }

            const config = await fetchConfig()

            myDashboard = await embedDashboard({
                id: props.dashboardId,
                supersetDomain: props.supersetDomain ?? config.supersetDomain,
                mountPoint: mountpointRef.current,
                fetchGuestToken: fetchGuestTokenFromBackend(props.dashboardId),
                dashboardUiConfig: {
                    hideTitle: props.dashboardUiConfig?.hideTitle ?? false,
                    hideChartControls: props.dashboardUiConfig?.hideChartControls ?? false,
                    filters: props.dashboardUiConfig?.filters ?? defaultFiltersConfig
                }
            })

            styleDashboardIframe(mountpointRef.current)
        }

        mountDashboard()

        return () => {
            if (myDashboard) {
                myDashboard.unmount()
            }
        }
    }, [props.dashboardId, props.supersetDomain])

    return <div ref={mountpointRef} style={{ width: '100%', height: '100%', position: 'relative' }}></div>
}
